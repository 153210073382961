import React  from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';

export default function Blog() {

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg"/>
        <h1>Brew City Hikers coming soon</h1>
    </React.Fragment>
  );
}
